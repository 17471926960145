import { Link } from "react-router-dom";
import { useTranslation } from "@i18n";
import stl from "./SellTradeHome.module.css";
import { CDN_LINK } from "@globalConfig";
import { useLang } from "../../../../common/Contexts/LanguageContext";
import ProgressiveImage from "@componentsShared/ProgressiveImage";

const SellTradeHome = () => {
  const { t } = useTranslation(["web_home", "web_common"]);
  const lang = useLang();
  return (
    <>
      <div className={stl.container}>
        {lang === "en" ? (
          <ProgressiveImage
            waitUntilInteractive
            src={CDN_LINK + "/assets/images/trade_sell_en.svg"}
            width={179}
            height={80}
            alt="trade_sell"
          />
        ) : (
          <ProgressiveImage
            waitUntilInteractive
            src={CDN_LINK + "/assets/images/trade_sell.svg"}
            width={179}
            height={80}
            alt="trade_sell"
          />
        )}
        <div>
          <strong>
            {t("ودّك تبيع أو تبدل سيارتك بأفضل سعر؟", {
              ns: "web_home",
            })}
          </strong>
          <p>
            {t(
              "وفر وقتك وبيع سيارتك بسهولة، بدون أي تكاليف، حنا نتكفل بكافة الإجراءات وفوق هذا فحص معتمد وتصوير إحترافي مجاني",
              {
                ns: "web_home",
              }
            )}
          </p>
          <a
            className="blueBtn"
            href={lang === "en" ? "/en/site/sell-trade" : "/site/sell-trade"}
            state="homepage_card"
          >
            {t("قدّم طلبك الآن", {
              ns: "web_home",
            })}
          </a>
        </div>
      </div>
    </>
  );
};

export default SellTradeHome;
